<template>
  <basic-container>
    <el-row>
      <el-col>
        <div>
          <el-card shadow="never">
            <div id="printBody">
              <!-- 自定义表单区域 -->

              <el-container>
                <div class="left-content">
                  <head-layout
                    :head-title="type !== 'view' ? '附件列表' : '附件预览'"
                  ></head-layout>
                  <div
                    style="
                      padding: 14px 12px;
                      background: #ffffff;
                      text-align: center;
                    "
                    v-if="type !== 'view'"
                  >
                    <el-upload
                      class="upload-demo"
                      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      ref="uploadRef"
                      :headers="headers"
                    >
                      <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-upload"
                        >点击上传</el-button
                      >
                    </el-upload>
                  </div>
                  <div
                    v-for="item in treeData"
                    :key="item.id"
                    class="img-content"
                    @click="getNodeClick(item)"
                  >
                    <div
                      class="img-item"
                      :class="item.isActive ? 'isActive' : ''"
                    >
                      <img
                        v-if="
                          item.extension == 'jpg' ||
                          item.extension == 'png' ||
                          item.extension == 'image/jpeg'
                        "
                        :src="item.link"
                        alt=""
                      />
                      <img
                        v-else
                        src="@/assets/images/dashboard-nodata.png"
                        alt=""
                      />
                      <i
                        v-if="type !== 'view'"
                        class="el-icon-circle-close delete-icon"
                        @click.stop="handleRemove(item)"
                      ></i>
                    </div>
                  </div>
                </div>
                <el-container>
                  <el-main style="background: #ffffff">
                    <head-layout
                      :head-btn-options="headBtnOptions"
                      :head-title="process.processDefinitionName"
                      @head-flow="headFlow"
                      @head-progress="headProgress"
                      @handle-examine="handleExamine"
                    ></head-layout>
                    <el-form
                      ref="form"
                      :model="form"
                      :rules="rules"
                      label-width="100px"
                      style="padding-top: 12px"
                    >
                      <el-form-item label="文件名称" prop="fileName">
                        <el-input
                          type="text"
                          v-model="form.fileName"
                          placeholder="请输入文件名称"
                          :disabled="type == 'view'"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="文件标签" prop="fileTag">
                        <el-tag
                          :key="tag"
                          v-for="tag in form.fileTag"
                          :closable="type !== 'view'"
                          :disable-transitions="false"
                          @close="handleClose(tag)"
                        >
                          {{ tag }}
                        </el-tag>
                        <el-input
                          class="input-new-tag"
                          v-if="inputVisible"
                          v-model="inputValue"
                          ref="saveTagInput"
                          size="small"
                          @keyup.enter.native="handleInputConfirm"
                          @blur="handleInputConfirm"
                        >
                        </el-input>
                        <el-button
                          v-if="type !== 'view' && !inputVisible"
                          class="button-new-tag"
                          size="small"
                          @click="showInput"
                          >新增标签</el-button
                        >
                      </el-form-item>
                      <el-form-item label="文件封面" prop="fileCover">
                        <el-upload
                          :class="{
                            uoloadSty: showImg,
                            disUoloadSty: noneUploadImg,
                          }"
                          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                          :headers="headers"
                          list-type="picture-card"
                          :file-list="fileList"
                          file="file"
                          :on-remove="handleDeleteImgRemove"
                          :on-success="handleFileCoverSuccess"
                          :on-change="uploadImgChange"
                          accept=".jpeg,.jpg,.gif,.png"
                          :limit="limitCountImg"
                        >
                          <i class="el-icon-plus"></i>
                          <div
                            slot="file"
                            slot-scope="{ file }"
                            style="width: 100%; height: 100%"
                          >
                            <img
                              class="el-upload-list__item-thumbnail"
                              :src="file.url"
                              alt=""
                            />
                            <span
                              class="el-upload-list__item-actions"
                              v-if="type !== 'view'"
                            >
                              <span
                                class="el-upload-list__item-delete"
                                @click="handleDeleteImgRemove"
                              >
                                <i class="el-icon-delete"></i>
                              </span>
                            </span>
                          </div>
                        </el-upload>
                      </el-form-item>
                      <el-form-item label="文件分类" prop="fileType">
                        <knowledge-classification
                          v-if="flag"
                          :selectedTagId="form.fileTypeId"
                          :showTagContent="type !== 'view'"
                          :view="type !== 'view'"
                          @select-tag="selectTag"
                        ></knowledge-classification>
                      </el-form-item>
                      <el-form-item label="适用范围" prop="scopeApplication">
                        <el-radio-group
                          v-model="form.scopeApplication"
                          :disabled="type == 'view'"
                        >
                          <el-radio label="1">单位级</el-radio>
                          <el-radio label="2">公司级</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item
                        v-if="form.scopeApplication == '1'"
                        prop="deptIdList"
                      >
                        <el-tree
                          ref="deptTree"
                          :data="deptTreeData"
                          show-checkbox
                          node-key="id"
                          @check-change="handleCheckChange"
                          :default-expand-all="true"
                          :default-checked-keys="defaultChecked"
                          :props="defaultProps"
                        >
                        </el-tree>
                      </el-form-item>
                      <el-form-item label="文件描述" prop="fileDesc">
                        <el-input
                          type="textarea"
                          v-model="form.fileDesc"
                          :disabled="type == 'view'"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </el-main>
                </el-container>
              </el-container>

              <!-- 自定义表单区域 -->
            </div>
          </el-card>
          <!-- 审批意见表单 -->
          <el-card
            shadow="never"
            style="margin-top: 20px"
            v-if="process.status == 'todo'"
          >
            <wf-examine-form
              ref="examineForm"
              :comment.sync="comment"
              :process="process"
              @user-select="handleUserSelect"
            ></wf-examine-form>
          </el-card>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      :title="$t(`cip.plat.wfOps.process.title.processProgressTitle`)"
      :visible.sync="circulationVisible"
      width="35%"
    >
      <wf-flow :flow="flow"></wf-flow>
    </el-dialog>

    <el-dialog
      :title="$t(`cip.plat.wfOps.process.title.flowChartHeadTitle`)"
      :visible.sync="trackVisible"
      width="60%"
    >
      <wf-design
        ref="bpmn"
        style="height: 400px"
        :options="bpmnOption"
      ></wf-design>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>

    <!-- 底部按钮 -->
    <wf-button
      :loading="submitLoading"
      :button-list="buttonList"
      :process="process"
      :comment="comment"
      @examine="handleExamine"
      @user-select="handleUserSelect"
      @print="handlePrint"
      @rollback="handleRollbackTask"
      @terminate="handleTerminateProcess"
      @withdraw="handleWithdrawTask"
    ></wf-button>
  </basic-container>
</template>

<script>
import WfExamineForm from "@/views/plugin/workflow/process/components/examForm.vue";
import WfButton from "@/views/plugin/workflow/process/components/button.vue";
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";
import userSelect from "@/views/plugin/workflow/process/components/user-select";

import exForm from "@/views/plugin/workflow/mixins/ex-form";
import theme from "@/views/plugin/workflow/mixins/theme";
import CommonTree from "@/views/business/knowledge/components/commonTree";
import knowledgeClassification from "@/views/business/knowledge/components/knowledge-classification/index";
import {
  saveUpload,
  getDetail,
  removeUploadFile,
} from "@/api/knowledgeClassifcation/knowledge";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { getToken } from "@/util/auth";
import { selectChildren } from "@/api/system/dept";
import website from "@/config/website";
export default {
  mixins: [exForm, theme],
  components: {
    userSelect,
    WfExamineForm,
    WfButton,
    WfFlow,
    HeadLayout,
    GridLayout,
    CommonTree,
    knowledgeClassification,
  },
  watch: {
    "$route.query.p": {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, "base64").toString());
          const { taskId, processInsId } = param;
          if (taskId && processInsId) this.getDetail(taskId, processInsId);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      flag: false,
      deptTreeData: [],
      defaultChecked: [],
      treeData: [],
      page: {
        pageSize: 999,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        label: "title",
        value: "id",
        key: "id",
      },
      //   标签
      inputVisible: false,
      inputValue: "",
      form: {
        fileName: "",
        fileCode: "",
        fileTag: [],
        fileCover: "",
        fileType: "",
        fileTypeId: "",
        scopeApplication: "",
        fileDesc: "",
        deptIdList: [],
      },
      rules: {
        fileName: [
          { required: true, message: "请输入文件名称", trigger: "change" },
        ],
        fileTag: [
          { required: true, message: "请新增文件标签", trigger: "blur" },
        ],
        fileCover: [
          { required: false, message: "请选择文件封面", trigger: "change" },
        ],
        fileType: [
          { required: true, message: "请选择文件分类", trigger: "change" },
        ],
        scopeApplication: [
          { required: true, message: "请选择适用范围", trigger: "change" },
        ],
        deptIdList: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
      },
      selectNode: {},
      showFileDialog: false,
      fileUrl: "",
      // 流程processIsFinished为reject,即驳回和状态为todo时type等于edit
      type: "view",
      showImg: true, //默认展示
      noneUploadImg: false, //
      fileList: [],
      limitCountImg: 1,
      trackVisible: false,
      circulationVisible: false,
      vars: [], // 需要提交的字段
      submitLoading: false, // 提交时按钮loading
      selectionList: [],
    };
  },
  computed: {
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      result.push({
        label: this.$t("cip.plat.wfOps.process.btn.flowBtn"),
        emit: "head-flow",
        type: "button",
        icon: "",
      });
      result.push({
        label: this.$t("cip.plat.wfOps.process.btn.processProgressBtn"),
        emit: "head-progress",
        type: "button",
        icon: "",
      });
      return result;
    },
  },
  mounted() {},
  methods: {
    setDisabled(obj) {
      obj.forEach((item) => {
        this.$set(item, "disabled", true);
        if (item.hasChildren) {
          this.setDisabled(item.children);
        }
      });
    },
    getTreeData() {
      selectChildren().then((res) => {
        if (res.data.code == 200) {
          this.deptTreeData = res.data.data;
          if (this.type == "view") {
            this.setDisabled(this.deptTreeData);
          }
        }
      });
    },
    handleCheckChange() {
      let checkedKeys = this.$refs.deptTree.getCheckedKeys();
      this.form.deptIdList = checkedKeys;
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL+"?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    handleRemove(file) {
      removeUploadFile(file.id).then(() => {
        this.treeData = this.treeData.filter((item) => item.id !== file.id);
      });
    },
    handleFileCoverSuccess(res, file) {
      this.form.fileCover = file.response.data.link;
    },
    // 判断数量，是否显示上传icon
    uploadImgChange(file, fileList) {
      this.noneUploadImg = fileList.length >= this.limitCountImg;
    },
    // 删除图片，判断数量，是否显示icon
    handleDeleteImgRemove() {
      this.fileList = [];
      this.noneUploadImg = this.fileList.length >= this.limitCountImg;
      this.form.fileCover = "";
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        isActive: false,
      });
    },
    getFiles(id) {
      getDetail(id).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          data.fileTag = data.fileTag.split(",");
          this.form = data;
          this.defaultChecked = this.form.deptIdList;
          if (this.form.fileCover) {
            console.log(this.noneUploadImg);
            this.noneUploadImg = true;
            console.log(this.noneUploadImg);
            this.fileList = [
              {
                url: this.form.fileCover,
              },
            ];
          } else {
            this.fileList = [];
          }
          this.treeData = data.attachList;
          if (this.treeData.length > 0) {
            this.treeData.forEach((item) => {
              item.isActive = true;
            });
            this.selectNode = this.treeData[0];
          }
        }
      });
    },
    getNodeClick(node) {
      node.isActive = true;
      this.treeData.forEach((item) => {
        if (item.id !== node.id) {
          item.isActive = false;
        }
      });
      this.selectNode = node;
      this.showFile(node);
    },
    handleClose(tag) {
      this.form.fileTag.splice(this.form.fileTag.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.fileTag.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    selectTag(list) {
      let fileType = [];
      let fileTypeId = [];
      if (list.length > 0) {
        list.forEach((item) => {
          fileType.push(item.cateLineCode);
          fileTypeId.push(item.id);
        });
      }
      this.form.fileType = fileType.toString();
      this.form.fileTypeId = fileTypeId.toString();
    },

    headFlow() {
      this.trackVisible = true;
    },
    headProgress() {
      this.circulationVisible = true;
    },
    // 获取任务详情
    getDetail(taskId, processInsId) {
      this.getTaskDetail(taskId, processInsId).then((res) => {
        const { process } = res;
        const { variables } = process;
        this.form = variables;
        // 自行处理相关逻辑
        this.getTreeData();
        this.flag = true;
        this.form.fileTag = variables.fileTag.split(",");
        this.getFiles(this.form.id);
        if (process.processIsFinished == "reject" && process.status == "todo") {
          this.type = "edit";
        }
        this.waiting = false; // 关闭骨架屏
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    headSave() {
      if (this.treeData.length == 0) {
        this.$message({
          type: "warning",
          message: "请在左侧附件列表上传附件",
        });
        return;
      }
      if (!this.selectNode.id) {
        this.$message({
          type: "warning",
          message: "请选择左侧附件列表附件",
        });
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.attachIds = this.selectNode.id;
          console.log(this.form.attachIds);
          this.form.fileTag = this.form.fileTag.toString();
          saveUpload(this.form).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "保存成功",
              });
            }
          });
        }
      });
    },
    // 审核
    handleExamine(pass) {
      this.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.type == "edit") {
            this.headSave();
            this.vars = [
              "fileName",
              "fileTag",
              "fileCover",
              "fileType",
              "fileTypeId",
              "scopeApplication",
              "fileDesc",
              "attachIds",
            ];
          }

          const variables = {};
          this.vars.forEach((v) => {
            if (v != "comment" && this.form[v]) variables[v] = this.form[v];
          });

          this.handleCompleteTask(pass, variables)
            .then(() => {
              this.$message.success("处理成功");
              this.handleCloseTag("/plugin/workflow/process/index");
            })
            .catch(() => {
              done();
              this.submitLoading = false;
            });
        } else {
          done();
          this.submitLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.left-content {
  width: 188px;
  box-sizing: border-box;
  border-right: 1px solid #cccccc;
  background: #fff;
  .img-content {
    padding: 3px;
    position: relative;
    .img-item {
      height: 100px;
      padding: 3px;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        width: 100%;
        height: 100%;
        background: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .delete-icon {
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 16px;
        color: red;
      }
    }
  }
  .isActive {
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}
.common_tree_handle {
  min-width: 188px;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
::v-deep .el-form-item__content {
  .el-tag {
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    margin-bottom: 5px;
    .el-icon-close {
      font-size: 16px;
    }
  }
}
</style>
